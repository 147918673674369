<template>
  <div class="home">
    <div v-if="this.$store.state.parkData" class="wrapper">
      <figure class="logo">
        <img src="img/03_onboarding/on_logo.png" alt="" />
      </figure>
      <div class="text-wrapper">
        <Transition name="fade" mode="out-in">
          <div class="firstText" :key="text" v-html="this.text"></div>
        </Transition>
        
        <div class="park-desc" v-html="this.$store.state.parkData.desc"></div>
      </div>
      <big-button :onClick="start">{{ $t("go2") }}</big-button>
    </div>
  </div>
</template>

<script>
import BigButton from "@/components/BigButton.vue";
import { Analytic } from "./../../public/js/analytics/Analytic.js";
var options = {
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Scanner",
};
var analytic = new Analytic(options);

const $ = require('jquery');
window.$ = $;

export default {
  name: "Home",
  components: {
    BigButton,
  },
  data() {
    return {
      text: null,
      interval: null,
      hometext: null,
    }
  },
  mounted() {
    this.changes = 0;
    if (this.$store.state.routeId) {
      var routeId = this.$store.state.routeId;
      if (routeId == "praca") {
        analytic.registerEvent(`Home: praca`, "open");
      } else {
        analytic.registerEvent(`Home: ${routeId}`, "open");
      }
      this.$store.commit("setHome", routeId);
    } else {
      analytic.registerEvent(`Home: praca`, "open");
      this.$store.commit("setHome", "praca");
    }

    this.homeText = this.$store.state.parkData.homeTexts;
  
    this.setText();
    this.interval = setInterval(() => {
      console.log("interval");
      const random = Math.floor(Math.random() * this.homeText.length);
      this.text = this.homeText[random];
    }, 10000);
  },
  watch: {
    "$store.state.parkData": function () {
      this.$i18n.locale = this.$store.state.locale;
      this.$root.$emit("initPlayerWith", this.$store.state.parkData.audio);
    },
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    setText(){
      const random = Math.floor(Math.random() * this.homeText.length);
      //$(".firstText").html(homeText[random]);
      this.text = this.homeText[random];
    },
    start() {
      if (this.$store.state.onboardingState !== null) {
        // ya se mostro el onboarding
        if (this.$store.state.routeId) {
          this.$router.push("route");
        } else {
          this.$router.push("scanner");
        }
      } else {
        // aun se tiene que mostrar el onboarding
        this.$router.push("OnBoarding");
      }
    },
  },
};
</script>

<style lang="scss" >
$small: 300px;
$medium: 600px;
.home {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;

  .big-button{
    width: 16rem;
    padding: 0.8rem 0.3rem;
  }

  .wrapper {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    overflow: auto;
    max-height: 100%;

    .logo {
      position: relative;
      width: 70%;
      height: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10%;
      margin-bottom: 5%;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .title {
      font-size: 1.8rem;
      margin: 1.5rem;
      text-transform: uppercase;
    }

    .text-wrapper {
      display: flex;
      flex-flow: column;
      flex: 1;
      overflow: auto;
      align-items: center;
      justify-content: flex-start;

      .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s ease;
      }
      .fade-enter, .fade-leave-to{
        opacity: 0;
      }

      .park-desc, .firstText {
        display: flex;
        flex-flow: column;
        text-align: center;
        align-items: center;
        font-family: "Lato";

        p {
          font-size: 22px;
          line-height: 2rem;
          text-align: center;

          b{
            color: #444444;
          }
        }
      }

      .park-desc{
        height: 210px;
        color: #272727;
      }

      .firstText{
        color: #5c5c5c;
        font-weight: bold;
        height: 185px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: "Myriad-bold";

        p{
          font-size: 23px;
        }
      }
    }
  }
}

@media (max-height: 700px) {
  .text-wrapper {
    justify-content: flex-start;
  }
}
@media (min-height: 701px) {
  .text-wrapper {
    justify-content: center;
  }
}
</style>
